import { Injectable } from '@angular/core';
import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class MainService {

  constructor(
    private http: HttpClient,
    private route: Router,
    private storageService: StorageService
  ) {}
  
  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${ENV.apiUrl}${path}`,{params:params}).pipe(catchError(this.formatErrors));
  }
  getdata(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${ENV.apiBaseUrl}${path}`,{params:params}).pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(`${ENV.apiUrl}${path}`,JSON.stringify(body)).pipe(catchError(this.formatErrors));
  }
  put1(path: string, body: Object = {}): Observable<any> {
    return this.http.put(`${ENV.apiUrl}${path}`,(body)).pipe(catchError(this.formatErrors));
  }

  patch(path: string, body: Object = {}): Observable<any> {
    return this.http.patch(`${ENV.apiUrl}${path}`,JSON.stringify(body)).pipe(catchError(this.formatErrors));
  }
  
  post(body: Object = {},path: string): Observable<any> {
    return this.http.post(`${ENV.apiUrl}${path}`,JSON.stringify(body)).pipe(catchError(this.formatErrors));
  }
  post2(path: string,body): Observable<any> {
    return this.http.post(`${ENV.apiUrl}${path}`,body).pipe(catchError(this.formatErrors));
  }
  post1(path: string,body): Observable<any> {
    return this.http.post(`${ENV.apiUrl}${path}`,body).pipe(catchError(this.formatErrors));
  }

  delete(path): Observable<any> {
    return this.http.delete(`${ENV.apiUrl}${path}`).pipe(catchError(this.formatErrors));
  }

  postFile(body: Object = {},path: string): Observable<any> {
    return this.http.post(`${ENV.apiBaseUrl}${path}`,body).pipe(catchError(this.formatErrors));
  }
  
  private formatErrors(error: any) {
    return throwError(error.error);
  }

  isLoggedIn(): boolean {
    let authToken = localStorage.getItem('bandoAccessToken');
    return authToken !== null ? true : false;
  }

  signOut(){
    const x=localStorage.getItem('fcmToken')
    // console.log(localStorage.getItem('fcmToken'))
    this.storageService.clearData();
    this.storageService.setData('fcmToken', x)
    this.route.navigate(['login'])
  }

}